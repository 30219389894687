body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
#root {
  height: 100vh;
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-calendar-picker-indicator {
  background-color: #fff;
}
